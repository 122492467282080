.margin {
  position: fixed;
  top: 0; left: 0; right: 0; bottom: 0;
  z-index: 11;

  &-tl,
  &-tr,
  &-bl,
  &-br {
    position: absolute;
    width: 90px; height: 88px;
  }

  &-tl {
    top: 10px; left: 10px;
  }
  &-tr {
    top: 10px; right: 10px;
    transform: scale(-1, 1);
  }
  &-bl {
    bottom: 10px; left: 10px;
    transform: rotate(270deg);
  }
  &-br {
    bottom: 10px; right: 10px;
    transform: scale(-1, 1) rotate(270deg);
  }

  &-line {
    &-t,
    &-r,
    &-b,
    &-l {
      position: absolute;
    }
    &-t,
    &-b {
      width: calc(100% - 198px);
      left: 50%;
      transform: translateX(-50%);
      height: 7px;
      top: 11px;
      background-repeat: repeat-x;
    }
    &-b {
      bottom: 11px;
      top: initial;
    }
    &-l,
    &-r {
      width: 7px;
      height: calc(100% - 198px);
      top: 98px;
      background-repeat: repeat-y;
    }
    &-l {
      left: 12px;
    }
    &-r {
      right: 12px;
    }
  }
  @media (min-width: 1300px) {
    &-tl {
      top: 20px; left: 20px;
    }
    &-tr {
      top: 20px; right: 20px;
    }
    &-bl {
      bottom: 20px; left: 20px;
    }
    &-br {
      bottom: 20px; right: 20px;
    }
    
    &-line {
      &-t {
        top: 21px;
      }
      &-b {
        bottom: 21px;
      }
      &-l {
        left: 22px;
      }
      &-r {
        right: 22px;
      }
    }
  }
}
.hero {
  width: 100%;
  height: 100vw;
  position: relative;
  overflow: hidden;

  &-bg {
    position: absolute;
    z-index: 1;
  }
  &-video {
    position: relative;
    object-fit: cover;
    object-position: center;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 100vw;
    z-index: 2;
  }

  @media (max-height: 470px) {
    height: 100vh;

    &-video {
      width: 100vw; height: 100vh;
    }
  }
  @media (min-width: 768px) {
    height: 500px;
    &-video {
      height: 500px;
    }
  }
  @media (min-width: 1024px) {
    height: 600px;
    &-video {
      height: 600px;
    }
  }
  @media (min-width: 1300px) {
    height: 100vh;
    &-video {
      height: 100vh;
    }
  }
}

.about {
  padding: 50px 35px;
  h2 {
    margin-bottom: 30px;
    font-weight: 600;
    font-size: 48px;
    line-height: 90%;
  }
  p {
    font-size: 20px;
  }
  @media (min-width: 768px) {
    padding: 80px 150px;
  }
  @media (min-width: 1024px) {
    padding: 80px 80px;
    display: grid;
    align-items: center;
    grid-template-columns: calc(55% - 80px) 45%;
    gap: 80px;
  }
  @media (min-width: 1300px) {
    padding: 100px 150px;
    h2 {
      margin-bottom: 50px;
      font-size: 80px;
    }
    p {
      font-size: 24px;
    }
  }
  @media (min-width: 1500px) {
    padding: 100px 0;
    width: 1400px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    gap: 200px;
    h2 {
      font-size: 104px;
      line-height: 83px;
    }
    p {
      margin-bottom: 25px;
      font-size: 33px;
      line-height: 37px;
    }
  }
}

.products {
  padding: 80px 0;
  text-align: center;
  background-color: #eeeeee;
  position: relative;
  z-index: 12;
  overflow: hidden;
  h2 {
    margin-bottom: 40px;
    font-weight: 600;
    font-size: 48px;
  }
  h3 {
    margin-top: 40px;
    font-weight: 600;
    font-size: 24px;
  }
  &-slide {
    width: calc(100% - 80px);
    margin: 0 auto;
  }
  &-list {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-item {
    padding: 20px;
    @include transition;
    &.glide__slide--active {
      padding: 0;
    }
  }
  &-arrows {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    transform: translateY(-25px);
  }
  &-arrow {
    border: none;
    padding: 0;
    display: flex;
    align-items: center;
    width: 40px; height: 40px;
    padding-top: 9px;
    background-color: rgba(#fff, .9);
    @include transition;
    cursor: pointer;
    overflow: hidden;
    &:hover {
      background-color: #fff;
    }
    svg {
      width: 40px;
      min-width: 40px;
      @include transition;
    }
    &--left {
      svg {
        transform: scaleX(-1);
      }
      &:hover {
        svg {
          transform: scaleX(-1) translateX(100%);
        }
      }
    }
    &--right {
      svg {
        transform: translateX(-100%);
      }
      &:hover {
        svg {
          transform: translateX(0);
        }
      }
    }
  }
  @media (min-width: 540px) {
    &-slide {
      width: 60vw;
      margin: 0 auto;
    }
  }
  @media (min-width: 1024px) {
    &-slide {
      width: 50vw;
    }
  }
  @media (min-width: 1300px) {
    padding: 100px 0;
    h2 {
      font-size: 80px;
      margin-bottom: 110px;
    }
    &-slide {
      width: 40vw;
    }
    &-item {
      padding: 40px;
    }
  }
  @media (min-width: 1500px) {
    h2 {
      font-size: 104px;
    }
    h3 {
      font-size: 33px;
      line-height: 38px;
    }
  }
}

.links {
  padding: 80px 40px;
  position: relative;
  z-index: 12;
  &-item {
    .button {
      margin: 0 auto 20px;
    }
    p {
      margin-bottom: 50px;
      font-size: 25px;
      text-align: center;
    }
    &:last-child {
      p {
        margin-bottom: 0;
      }
    }
  }
  @media (min-width: 768px) {
    padding: 100px 160px;
  }
  @media (min-width: 1024px) {
    &-item {
      display: flex;
      align-items: center;
      gap: 50px;
      &:not(:last-child) {
        margin-bottom: 50px;
      }
      .button {
        min-width: 240px;
        margin-bottom: 0;
      }
      p {
        text-align: left;
        margin-bottom: 0;
      }
    }
  }
  @media (min-width: 1300px) {
    padding: 100px 250px;
  }
  @media (min-width: 1500px) {
    padding: 100px 0;
    &-item {
      max-width: 1000px;
      margin: 0 auto;
    }
    p {
      font-size: 33px;
      line-height: 33px;
    }
    .button {
      min-width: 340px;
    }
  }
}