.footer {
  margin: 0 50px;
  padding: 50px 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 40px;
  border-top: 2px solid $main-color;
  position: relative;
  z-index: 12;

  &-logo {
    margin: 0 auto;
  }

  &-social {
    display: flex;
    align-items: center;
    gap: 30px;

    a {
      position: relative;
      width: 39px;
      height: 39px;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      img {
        @include transition;
      }
      &:hover {
        img {
          transform: translateY(-100%);
        }
      }
    }
  }

  @media (min-width: 1300px) {
    margin: 0 100px;
    padding: 60px 0 90px;
    flex-direction: row;
    justify-content: center;
    &-logo {
      margin: 0;
    }
  }
}