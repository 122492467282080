@mixin font($weight, $size, $color: null, $family: $main-font, $line: null, $align: null) {
  font-family: $family;
  font-weight: $weight;
  font-size: $size;
  color: $color;
  line-height: $line;
  text-align: $align;
}
@mixin transition($speed: .2s) {
  transition: all $speed cubic-bezier(.215,.61,.355,1);
}

@mixin flexCenter() {
  display: flex;
  align-items: center;
  justify-content: center;
}