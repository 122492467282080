.header {
  width: 100%;
  padding: 40px 0 0;
  z-index: 10;
  &-overlay {
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%);
    z-index: -1;
  }
  .container {
    display: flex;
    justify-content: center;
  }
  &-logo {
    display: none;
    position: absolute;
    left: 65px;
    top: 50%;
    transform: translateY(-50%);
  }
  &-title {
    max-width: 60vw;
    text-align: center;
    
    h1 {
      text-transform: uppercase;
      color: $main-color;
      margin-top: 10px;
      font-weight: 300;
      font-size: clamp(14px, 4vw, 27px);
      color: $secondary-color;
    }
  }
  @media (min-width: 768px) {
    &-logo {
      display: block;
    }
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    &-title {
      max-width: 40vw;

      h1 {
        
        font-size: clamp(14px, 2.5vw, 27px);
      }
    }
  }
  @media (min-width: 1300px) {
    padding: 90px 0 0; 
    &-logo {
      left: 120px;
    }
    &-title {
      display: flex;
      align-items: center;
      max-width: initial;
      text-align: left;
      gap: 10px;
      h1 {
        margin-top: 0;
      }
    }
  }
}