button,
a,
input[type=submit] {
  &.button {
    position: relative;
    @include flexCenter;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    @include transition;
    width: fit-content;
    height: 60px;
    width: 265px;
    border: none;
    border: 2px solid $main-color;
    background-color: transparent;
    font-family: $main-font;
    font-weight: 700;
    font-size: 35px;
    color: $secondary-color;
    cursor: pointer;
    z-index: 1;
    overflow: hidden;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 505px;
      height: 120px;
      border-radius: 100%;
      background-color: $main-color;
      z-index: -1;
      transform: translate(-50%, -50%) scale(0);
      transform-origin: center;
      @include transition;
    }

    span {
      display: flex;
      align-items: center;
      justify-content: center; 
      @include transition;
      min-height: 60px;
    }
    &:hover {
      &::after {
        transform: translate(-50%, -50%) scale(1);
      }
      span {
        transform: translateY(-100%);
        color: #fff;
      }
    }

    @media (min-width: 1500px) {
      width: 340px;
      height: 80px;

      span {
        min-height: 80px;
      }
    }
  }
}