body {
  color: $secondary-color;
  background-color: #fff;
  font-family: $main-font;
  overflow: hidden;
  @include transition;
}

.scroller {
  width: 100vw;
  height: 100vh;
  overflow: auto;
}

p {
  margin-bottom: 15px;
  font-size: 16px;
  line-height: 140%;
  color: $secondary-color;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.hide {
  display: none !important;
}