.cursor {
	display: none;
}

@media (any-pointer: fine) {
	.cursor {
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    mix-blend-mode: exclusion;
    background-color: transparent;
    border: 3px solid #fff;
    background-color: #fff;
    z-index: 9999;
    user-select: none;
    pointer-events: none;
    @include transition;
  } 
}